import { formatDateToBeSent, getKeyName } from "@local/utils";
import { allowedAgencies } from "@local/utils/constants";

export const adaptWorkingLanguages = (languages) => Object.keys(languages).map((key) => ({ value: key, label: languages[key] }));

const getBooleanString = (value) => {
  if (value === null) return undefined;
  if (value === true) return "true";
  return "false";
};

const adaptExperiencesInitialStateValues = (experiences) => {
  const result = [];
  experiences.forEach((item) => {
    const foundItemIndex = result.findIndex((toFindItem) => toFindItem.sector === item.specialization?.category?.id);
    if (foundItemIndex !== -1) {
      result[foundItemIndex].areas.push(item.specialization?.id);
    } else {
      result.push({
        id: item?.specialization?.category?.id,
        sector: item?.specialization?.category?.id,
        years: item.years,
        areas: [item.specialization?.id],
      });
    }
  });
  return result;
};

const adaptLocationsInitialStateValues = (locations) => {
  return locations.map((item, index) => ({
    id: `partner_location_${item.lat}_${item.lon}_${index}`,
    label: `${item.admin_level_1.name}, ${item.admin_level_1.country_name} (${item.admin_level_1.country_code})`,
    adminLevelOne: {
      name: item.admin_level_1.name,
      countryCode: item.admin_level_1.country_code,
    },
    latitude: item.lat,
    longitude: item.lon,
  }));
};

const adaptExperiencesToBeSent = (experiences, formValues) => {
  const result = [];
  experiences.forEach((item) => {
    formValues[getKeyName("experiences_areas", item.id)]?.forEach((area) => {
      result.push({
        years: formValues[getKeyName("experiences_years", item.id)],
        specialization_id: area,
        id: item.originalId ?? undefined,
      });
    });
  });
  return result;
};

const adaptLocationsToBeSent = (locations) => {
  return locations.map((location) => ({
    admin_level_1: {
      name: location.adminLevelOne.name,
      country_code: location.adminLevelOne.countryCode,
    },
    lat: location.latitude,
    lon: location.longitude,
  }));
};

const adaptSpecializationsToBeSent = (experiences, formValues) => {
  const result = [];
  experiences.forEach((item) => {
    result.push({
      sector: formValues[getKeyName("experiences_sector", item.id)],
      years: formValues[getKeyName("experiences_years", item.id)],
      areas: formValues[getKeyName("experiences_areas", item.id)],
      id: item.originalId ?? undefined,
    });
  });
  return result;
};

const adaptBudgetsToBeSent = (budgets, formValues) => {
  const result = [];
  budgets.forEach((item) => {
    result.push({
      id: item.id,
      year: parseInt(item.year),
      budget: formValues[getKeyName("budgets_budget", item.id)],
    });
  });
  return result;
};

const adaptAreasOfResponsibilityToBeSent = (areasOfResponsibility, formValues, partner) => {
  const result = [];
  areasOfResponsibility.forEach((item) => {
    if ((formValues[getKeyName("internal_controls_segregation_duties", item.value)] ?? undefined) !== undefined) {
      result.push({
        id: partner?.internal_controls?.find((element) => element.functional_responsibility === item.value)?.id ?? undefined,
        functional_responsibility: item.value,
        segregation_duties: formValues[getKeyName("internal_controls_segregation_duties", item.value)],
        comment: formValues[getKeyName("internal_controls_comment", item.value)],
      });
    }
  });
  return result;
};

const adaptAreasOfPolicyToBeSent = (areasOfPolicy, formValues, partner) => {
  const result = [];
  areasOfPolicy.forEach((item) => {
    if ((formValues[getKeyName("area_policies_document_policies", item.value)] ?? undefined) !== undefined) {
      result.push({
        id: partner?.area_policies?.find((element) => element.area === item.value)?.id ?? undefined,
        area: item.value,
        document_policies: formValues[getKeyName("area_policies_document_policies", item.value)],
        partner: partner?.partner_additional?.id,
      });
    }
  });
  return result;
};

export const adaptIdentificationInitialFormValues = (partner) => {
  if (!partner) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }

  const have_governing_document = getBooleanString(partner?.profile?.have_governing_document);
  const registered_to_operate_in_country = getBooleanString(partner?.profile?.registered_to_operate_in_country);

  let governingDocumentsFormValues = {};
  let governmentDocuments = partner?.profile?.governing_documents ?? [];
  if (have_governing_document?.toString() === "true" && governmentDocuments.length === 0) governmentDocuments = [{ originalId: undefined }];
  governmentDocuments = governmentDocuments.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    editable: item?.editable ?? true,
    document: item?.document ?? undefined,
  }));
  governmentDocuments.forEach((item, index) => {
    governingDocumentsFormValues[getKeyName("governing_document", index)] = item.document;
  });

  let registrationDocumentsFormValues = {};
  let registrationDocuments = partner?.profile?.registration_documents ?? [];
  if (registered_to_operate_in_country?.toString() === "true" && registrationDocuments.length === 0) registrationDocuments = [{ originalId: undefined }];
  registrationDocuments = registrationDocuments.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    editable: item?.editable ?? true,
    document: item?.document ?? undefined,
    issue_date: item?.issue_date ? new Date(item.issue_date) : null,
    issuing_authority: item?.issuing_authority ?? "",
    registration_number: item?.registration_number ?? "",
  }));
  registrationDocuments.forEach((item, index) => {
    registrationDocumentsFormValues[getKeyName("registration_document", index)] = item.document;
    registrationDocumentsFormValues[getKeyName("registration_issue_date", index)] = item.issue_date;
    registrationDocumentsFormValues[getKeyName("registration_issuing_authority", index)] = item.issuing_authority;
    registrationDocumentsFormValues[getKeyName("registration_registration_number", index)] = item.registration_number;
  });

  return {
    formValues: {
      legal_name: partner?.legal_name ?? "",
      other_names: partner?.profile?.other_names ?? [],
      country_code: partner?.country_code ?? "",
      display_type: partner?.display_type ?? "",
      year_establishment: partner?.profile?.year_establishment ?? "",
      missing_governing_document_comment: partner?.profile?.missing_governing_document_comment ?? "",
      missing_registration_document_comment: partner?.profile?.missing_registration_document_comment ?? "",
      have_governing_document,
      registered_to_operate_in_country,
      ...governingDocumentsFormValues,
      ...registrationDocumentsFormValues,
    },
    stateValues: {
      governmentDocuments,
      registrationDocuments,
    },
  };
};

export const adaptContactInformationInitialFormValues = (partner) => {
  if (!partner) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }

  const have_board_directors = getBooleanString(partner?.profile?.have_board_directors);

  let boardDirectorsFormValues = {};
  let boardDirectors = partner?.directors ?? [];
  if (have_board_directors?.toString() === "true" && boardDirectors.length === 0) boardDirectors = [{ originalId: undefined }];
  boardDirectors = boardDirectors.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    fullname: item?.fullname ?? "",
    job_title: item?.job_title ?? [],
    job_title_other: item?.job_title_other ?? "",
    telephone: item?.telephone ?? "",
    mobile: "",
    email: item?.email ?? "",
    authorized: item?.authorized ?? "",
  }));
  boardDirectors.forEach((item, index) => {
    boardDirectorsFormValues[getKeyName("board_director_fullname", index)] = item.fullname;
    boardDirectorsFormValues[getKeyName("board_director_job_title", index)] = item.job_title;
    boardDirectorsFormValues[getKeyName("job_title_other", index)] = item.job_title_other;
    boardDirectorsFormValues[getKeyName("board_director_telephone", index)] = item.telephone;
    boardDirectorsFormValues[getKeyName("board_director_mobile", index)] = "";
    boardDirectorsFormValues[getKeyName("board_director_email", index)] = item.email;
    boardDirectorsFormValues[getKeyName("board_director_authorized", index)] = item.authorized;
  });

  return {
    formValues: {
      mailing_type: partner?.mailing_address?.mailing_type ?? "",
      street: partner?.mailing_address?.street ?? "",
      po_box: partner?.mailing_address?.po_box ?? "",
      city: partner?.mailing_address?.city ?? "",
      country: partner?.mailing_address?.country ?? "",
      zip_code: partner?.mailing_address?.zip_code ?? "",
      mailing_telephone: partner?.mailing_address?.mailing_telephone ?? "",
      website: partner?.mailing_address?.website ?? "",
      org_email: partner?.mailing_address?.org_email ?? "",
      hq_head_of_organization_fullname: partner?.hq_head_of_organization?.at(0)?.fullname ?? "",
      hq_head_of_organization_job_title: partner?.hq_head_of_organization?.at(0)?.job_title ?? [],
      hq_head_of_organization_job_title_other: partner?.hq_head_of_organization?.at(0)?.job_title_other ?? "",
      hq_head_of_organization_telephone: partner?.hq_head_of_organization?.at(0)?.telephone ?? "",
      hq_head_of_organization_email: partner?.hq_head_of_organization?.at(0)?.email ?? "",
      working_languages: partner?.profile?.working_languages ?? [],
      working_languages_other: partner?.profile?.working_languages_other ?? "",
      ...boardDirectorsFormValues,
    },
    stateValues: {
      boardDirectors,
    },
  };
};

export const adaptMandateMissionInitialFormValues = (partner) => {
  if (!partner) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }

  const have_board_directors = getBooleanString(partner?.profile?.have_board_directors);
  const ethic_safeguard = getBooleanString(partner?.mandate_mission?.ethic_safeguard);
  const ethic_fraud = getBooleanString(partner?.mandate_mission?.ethic_fraud);
  const population_of_concern = getBooleanString(partner?.mandate_mission?.population_of_concern);
  const social_and_environmental_standards = getBooleanString(partner?.mandate_mission?.social_and_environmental_standards);

  let experiencesFormValues = {};
  let experiences = partner?.experiences ?? [];
  if (experiences.length === 0) {
    experiences = [{ id: 0 }];
  } else {
    experiences = adaptExperiencesInitialStateValues(experiences);
  }
  experiences.forEach((item) => {
    experiencesFormValues[getKeyName("experiences_sector", item.id)] = item.sector;
    experiencesFormValues[getKeyName("experiences_years", item.id)] = item.years;
    experiencesFormValues[getKeyName("experiences_areas", item.id)] = item.areas;
  });

  let locations = partner?.location_field_offices ?? [];
  locations = adaptLocationsInitialStateValues(locations);

  return {
    formValues: {
      mandate_and_mission: partner?.mandate_mission?.mandate_and_mission ?? "",
      governance_structure: partner?.mandate_mission?.governance_structure ?? "",
      governance_hq: partner?.mandate_mission?.governance_hq ?? "",
      governance_organigram: partner?.mandate_mission?.governance_organigram ?? "",
      ethic_safeguard_comment: partner?.mandate_mission?.ethic_safeguard_comment ?? "",
      ethic_safeguard_policy: partner?.mandate_mission?.ethic_safeguard_policy ?? "",
      ethic_fraud_comment: partner?.mandate_mission?.ethic_fraud_comment ?? "",
      ethic_fraud_policy: partner?.mandate_mission?.ethic_fraud_policy ?? "",
      concern_groups: partner?.mandate_mission?.concern_groups ?? "",
      country_presence: partner?.country_presence ?? "",
      country_code: partner?.country_code ?? "",
      staff_globally: partner?.staff_globally ?? "",
      staff_in_country: partner?.staff_in_country ?? "",
      security_desc: partner?.mandate_mission?.security_desc ?? "",
      org_description: partner?.mandate_mission?.org_description ?? [],
      org_description_other: partner?.mandate_mission?.org_description_other ?? "",
      allegations_link: partner?.mandate_mission?.allegations_link ?? "",
      ethic_safeguard,
      ethic_fraud,
      social_and_environmental_standards,
      population_of_concern,
      ...experiencesFormValues,
      have_board_directors,
    },
    stateValues: {
      experiences,
      locations,
    },
  };
};

export const adaptFundingInitialFormValues = (partner) => {
  if (!partner) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }

  const have_core_funding = getBooleanString(partner?.fund?.have_core_funding);

  let budgetsFormValues = {};
  let budgets = partner?.budgets ?? [];
  budgets.forEach((item) => {
    budgetsFormValues[getKeyName("budgets_year", item.id)] = item.year;
    budgetsFormValues[getKeyName("budgets_budget", item.id)] = item.budget;
  });

  return {
    formValues: {
      major_donors: partner?.fund?.major_donors ?? [],
      main_donors_list: partner?.fund?.main_donors_list ?? "",
      have_core_funding,
      ...budgetsFormValues,
    },
  };
};

export const adaptCollaborationInitialFormValues = (partner) => {
  if (!partner) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }

  const any_partnered_with_un = getBooleanString(partner?.profile?.any_partnered_with_un);
  const partnership_collaborate_institution = getBooleanString(partner?.profile?.partnership_collaborate_institution);

  let collaboratedFormValues = {};
  let collaborated = partner?.collaborations_partnership ?? [];
  if (partnership_collaborate_institution?.toString() === "true" && collaborated.length === 0) collaborated = [{ originalId: undefined }];
  collaborated = collaborated.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    agency_name: item.agency_name,
    description: item.description,
    partner_number: item.partner_number,
  }));
  collaborated.forEach((item, index) => {
    collaboratedFormValues[getKeyName("collaborated_agency_name", index)] = item.agency_name;
    collaboratedFormValues[getKeyName("collaborated_description", index)] = item.description;
    collaboratedFormValues[getKeyName("collaborated_partner_number", index)] = item.partner_number;
  });

  return {
    formValues: {
      partnership_collaborate_institution_desc: partner?.profile?.partnership_collaborate_institution_desc ?? "",
      any_partnered_with_un,
      partnership_collaborate_institution,
      ...collaboratedFormValues,
    },
    stateValues: {
      collaborated,
    },
  };
};

export const adaptProjectImplementationInitialFormValues = (partner) => {
  if (!partner) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }

  const have_management_approach = getBooleanString(partner?.profile?.have_management_approach);
  const have_system_monitoring = getBooleanString(partner?.profile?.have_system_monitoring);
  const have_feedback_mechanism = getBooleanString(partner?.profile?.have_feedback_mechanism);
  const have_system_track = getBooleanString(partner?.profile?.have_system_track);
  const experienced_staff = getBooleanString(partner?.profile?.experienced_staff);
  const have_bank_account = getBooleanString(partner?.profile?.have_bank_account);
  const regular_audited = getBooleanString(partner?.audit?.regular_audited);
  const major_accountability_issues_highlighted = getBooleanString(partner?.audit?.major_accountability_issues_highlighted);
  const regular_capacity_assessments = getBooleanString(partner?.audit?.regular_capacity_assessments);
  const publish_annual_reports = getBooleanString(partner?.report?.publish_annual_reports);

  let auditsFormValues = {};
  let audits = partner?.audit_reports ?? [];
  if (regular_audited?.toString() === "true" && audits.length === 0) audits = [{ originalId: undefined }];
  audits = audits.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    org_audit: item?.org_audit,
    most_recent_audit_report: item?.most_recent_audit_report,
    audit_link_report: item?.audit_link_report,
  }));
  audits.forEach((item, index) => {
    auditsFormValues[getKeyName("audit_reports_org_audit", index)] = item.org_audit;
    auditsFormValues[getKeyName("audit_reports_most_recent_audit_report", index)] = item.most_recent_audit_report;
    auditsFormValues[getKeyName("audit_reports_audit_link_report", index)] = item.audit_link_report;
  });

  let assessmentsFormValues = {};
  let assessments = partner?.capacity_assessments ?? [];
  if (regular_capacity_assessments?.toString() === "true" && assessments.length === 0) assessments = [{ originalId: undefined }];
  assessments = assessments.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    assessment_type: item.assessment_type,
    report_file: item.report_file,
  }));
  assessments.forEach((item, index) => {
    assessmentsFormValues[getKeyName("capacity_assessments_assessment_type", index)] = item.assessment_type;
    assessmentsFormValues[getKeyName("capacity_assessments_report_file", index)] = item.report_file;
  });

  let areasOfResponsibilityFormValues = {};
  let areasOfResponsibility = partner?.internal_controls ?? [];
  areasOfResponsibility.forEach((item) => {
    areasOfResponsibilityFormValues[getKeyName("internal_controls_functional_responsibility", item.functional_responsibility)] = item.functional_responsibility;
    areasOfResponsibilityFormValues[getKeyName("internal_controls_segregation_duties", item.functional_responsibility)] = item.segregation_duties;
    areasOfResponsibilityFormValues[getKeyName("internal_controls_comment", item.functional_responsibility)] = item.comment;
  });

  let areasOfPolicyFormValues = {};
  let areasOfPolicy = partner?.area_policies ?? [];
  areasOfPolicy.forEach((item) => {
    areasOfPolicyFormValues[getKeyName("area_policies_document_area", item.area)] = item.area;
    areasOfPolicyFormValues[getKeyName("area_policies_document_policies", item.area)] = item.document_policies;
  });

  return {
    formValues: {
      partnership_collaborate_institution_desc: partner?.profile?.partnership_collaborate_institution_desc ?? "",
      management_approach_desc: partner?.profile?.management_approach_desc ?? "",
      system_monitoring_desc: partner?.profile?.system_monitoring_desc ?? "",
      feedback_mechanism_desc: partner?.profile?.feedback_mechanism_desc ?? "",
      org_acc_system: partner?.profile?.org_acc_system ?? "",
      method_acc: partner?.profile?.method_acc ?? "",
      financial_control_system_desc: partner?.profile?.financial_control_system_desc ?? "",
      experienced_staff_desc: partner?.profile?.experienced_staff_desc ?? "",
      explain: partner?.profile?.explain ?? "",
      regular_audited_comment: partner?.audit?.regular_audited_comment ?? "",
      comment: partner?.audit?.comment ?? "",
      key_result: partner?.report?.key_result ?? "",
      project_impl_report: partner?.report?.report ?? undefined,
      last_report: partner?.report?.last_report ? new Date(partner?.report?.last_report) : "",
      link_report: partner?.report?.link_report ?? "",
      have_management_approach,
      have_system_monitoring,
      have_feedback_mechanism,
      have_system_track,
      experienced_staff,
      have_bank_account,
      regular_audited,
      major_accountability_issues_highlighted,
      regular_capacity_assessments,
      publish_annual_reports,
      ...auditsFormValues,
      ...assessmentsFormValues,
      ...areasOfResponsibilityFormValues,
      ...areasOfPolicyFormValues,
    },
    stateValues: {
      audits,
      assessments,
    },
  };
};

export const adaptEditableRegistrationDocuments = (formValues, registrationDocument) => {
  return (
    formValues[getKeyName("registration_document", registrationDocument.id)]?.id !== registrationDocument?.document?.id ||
    formValues[getKeyName("registration_registration_number", registrationDocument.id)] !== registrationDocument?.registration_number ||
    formValues[getKeyName("registration_issuing_authority", registrationDocument.id)] !== registrationDocument?.issuing_authority ||
    formatDateToBeSent(formValues[getKeyName("registration_issue_date", registrationDocument.id)]) !== formatDateToBeSent(registrationDocument?.issue_date)
  );
};

export const adaptOtherInformationInitialFormValues = (partner) => {
  if (!partner) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }

  const confirm_data_updated = getBooleanString(partner?.other_info?.confirm_data_updated);
  const any_accreditation = getBooleanString(partner?.profile?.any_accreditation);

  let accreditationFormValues = {};
  let accreditation = partner?.collaboration_evidences?.filter((item) => item.mode === "Acc") ?? [];
  if (any_accreditation?.toString() === "true" && accreditation.length === 0) accreditation = [{ originalId: undefined }];
  accreditation = accreditation.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    organization_name: item.organization_name,
    date_received: new Date(item.date_received),
    evidence_file: item.evidence_file,
    editable: item?.editable ?? true,
  }));
  accreditation.forEach((item, index) => {
    accreditationFormValues[getKeyName("accreditation_organization_name", index)] = item.organization_name;
    accreditationFormValues[getKeyName("accreditation_date_received", index)] = item.date_received;
    accreditationFormValues[getKeyName("accreditation_evidence_file", index)] = item.evidence_file;
  });

  return {
    formValues: {
      info_to_share: partner?.other_info?.info_to_share ?? "",
      other_doc_1: partner?.other_info?.other_doc_1,
      other_doc_2: partner?.other_info?.other_doc_2,
      other_doc_3: partner?.other_info?.other_doc_3,
      other_doc_4: partner?.other_info?.other_doc_4,
      other_doc_5: partner?.other_info?.other_doc_5,
      other_doc_6: partner?.other_info?.other_doc_6,
      other_doc_7: partner?.other_info?.other_doc_7,
      other_doc_8: partner?.other_info?.other_doc_8,
      other_doc_9: partner?.other_info?.other_doc_9,
      any_accreditation,
      org_logo: partner?.other_info?.org_logo,
      confirm_data_updated,
      ...accreditationFormValues,
    },
    stateValues: {
      accreditation,
    },
  };
};

export const adaptIdentificationFormValuesToBeSent = (formValues, additionalParams) => {
  return {
    legal_name: formValues.legal_name,
    other_names: formValues.other_names,
    type_org: formValues.display_type,
    year_establishment: formValues.year_establishment,
    have_governing_document: formValues.have_governing_document?.toString() === "true" ? true : false,
    missing_governing_document_comment: formValues.missing_governing_document_comment,
    registered_to_operate_in_country: formValues.registered_to_operate_in_country?.toString() === "true" ? true : false,
    missing_registration_document_comment: formValues.missing_registration_document_comment,
    governing_documents: additionalParams.governmentDocuments
      .filter((item) => formValues[getKeyName("governing_document", item.id)]?.id)
      .map((item) => ({
        id: item.originalId ?? undefined,
        document: formValues[getKeyName("governing_document", item.id)]?.id !== item?.document?.id ? formValues[getKeyName("governing_document", item.id)]?.id : undefined,
        editable: item?.editable ?? true,
      })),
    registration_documents: additionalParams.registrationDocuments.map((item) => ({
      id: item.originalId ?? undefined,
      document: formValues[getKeyName("registration_document", item.id)]?.id !== item?.document?.id ? formValues[getKeyName("registration_document", item.id)]?.id : undefined,
      registration_number: formValues[getKeyName("registration_registration_number", item.id)],
      issuing_authority: formValues[getKeyName("registration_issuing_authority", item.id)],
      issue_date: formatDateToBeSent(formValues[getKeyName("registration_issue_date", item.id)]),
      editable: adaptEditableRegistrationDocuments(formValues, item) ?? true,
    })),
  };
};

export const adaptContactInformationFormValuesToBeSent = (formValues, additionalParams) => {
  return {
    mailing_address: {
      mailing_type: formValues?.mailing_type,
      street: formValues?.street,
      po_box: formValues?.po_box,
      city: formValues?.city,
      country: formValues?.country,
      zip_code: formValues?.zip_code,
      mailing_telephone: formValues?.mailing_telephone,
      website: formValues?.website,
      org_email: formValues?.org_email,
    },
    working_languages: formValues?.working_languages,
    working_languages_other: formValues?.working_languages_other,
    directors: additionalParams.boardDirectors.map((item) => ({
      id: item.originalId ?? undefined,
      fullname: formValues[getKeyName("board_director_fullname", item.id)],
      job_title: formValues[getKeyName("board_director_job_title", item.id)],
      job_title_other: formValues[getKeyName("job_title_other", item.id)],
      telephone: formValues[getKeyName("board_director_telephone", item.id)],
      email: formValues[getKeyName("board_director_email", item.id)],
      authorized: formValues[getKeyName("board_director_authorized", item.id)],
    })),
  };
};

export const adaptMandateMissionFormValuesToBeSent = (formValues, additionalParams) => {
  return {
    mandate_and_mission: formValues?.mandate_and_mission,
    governance_structure: formValues?.governance_structure,
    governance_hq: formValues?.governance_hq,
    governance_organigram: formValues?.governance_organigram?.id,
    ethic_safeguard_comment: formValues?.ethic_safeguard_comment,
    ethic_safeguard_policy: formValues?.ethic_safeguard?.toString() === "true" ? formValues?.ethic_safeguard_policy?.id : undefined,
    ethic_fraud_comment: formValues?.ethic_fraud_comment,
    ethic_fraud_policy: formValues?.ethic_fraud?.toString() === "true" ? formValues?.ethic_fraud_policy?.id : undefined,
    concern_groups: formValues?.population_of_concern?.toString() === "true" ? formValues?.concern_groups : [],
    country_presence: formValues?.country_presence,
    staff_globally: formValues?.staff_globally,
    staff_in_country: formValues?.staff_in_country,
    ethic_safeguard: formValues?.ethic_safeguard?.toString() === "true" ? true : false,
    ethic_fraud: formValues?.ethic_fraud?.toString() === "true" ? true : false,
    population_of_concern: formValues?.population_of_concern?.toString() === "true" ? true : false,
    social_and_environmental_standards: formValues?.social_and_environmental_standards?.toString() === "true" ? true : false,
    security_desc: formValues?.security_desc,
    experiences: adaptExperiencesToBeSent(additionalParams.experiences, formValues),
    location_field_offices: adaptLocationsToBeSent(additionalParams.locations),
    specializations: adaptSpecializationsToBeSent(additionalParams.experiences, formValues),
    org_description: formValues?.org_description,
    org_description_other: formValues?.org_description_other,
    allegations_link: formValues?.allegations_link,
    have_board_directors: formValues?.have_board_directors?.toString() === "true" ? true : false,
  };
};

export const adaptFundingFormValuesToBeSent = (formValues, partner) => {
  return {
    have_core_funding: formValues.have_core_funding?.toString() === "true" ? true : false,
    main_donors_list: formValues?.main_donors_list,
    major_donors: formValues?.major_donors,
    budgets: adaptBudgetsToBeSent(partner?.budgets, formValues),
  };
};

export const adaptCollaborationFormValuesToBeSent = (formValues, additionalParams) => {
  let collaborations_partnership = [];
  if (formValues.any_partnered_with_un?.toString() === "true") {
    collaborations_partnership = additionalParams?.collaborated?.map((item) => ({
      agency_name: formValues[getKeyName("collaborated_agency_name", item.id)].replaceAll("_", " "),
      description: formValues[getKeyName("collaborated_description", item.id)],
      partner_number: formValues[getKeyName("collaborated_partner_number", item.id)],
    }));
  }
  let collaboration_evidences = [];
  return {
    partnership_collaborate_institution_desc: formValues?.partnership_collaborate_institution_desc,
    any_partnered_with_un: formValues.any_partnered_with_un?.toString() === "true" ? true : false,
    partnership_collaborate_institution:formValues.partnership_collaborate_institution,
    collaborations_partnership,
    collaboration_evidences,
  };
};

export const adaptProjectImplementationFormValuesToBeSent = (formValues, additionalParams, areasOfResponsibility, areasOfPolicy, partner) => {
  return {
    management_approach_desc: formValues?.management_approach_desc,
    system_monitoring_desc: formValues?.system_monitoring_desc,
    feedback_mechanism_desc: formValues?.feedback_mechanism_desc,
    org_acc_system: formValues?.org_acc_system,
    method_acc: formValues?.method_acc,
    financial_control_system_desc: formValues?.financial_control_system_desc,
    experienced_staff_desc: formValues?.experienced_staff?.toString() === "true" ? formValues?.experienced_staff_desc : undefined,
    have_bank_account: formValues?.have_bank_account,
    explain: formValues?.explain,
    comment: formValues?.comment,
    regular_audited_comment: formValues?.regular_audited_comment,
    key_result: formValues?.key_result,
    report: formValues?.publish_annual_reports?.toString() === "true" && formValues?.project_impl_report?.id?.toString() !== partner?.report?.report?.id?.toString() ? formValues?.project_impl_report?.id : undefined,
    last_report: formatDateToBeSent(formValues?.last_report),
    link_report: formValues?.link_report,
    have_management_approach: formValues?.have_management_approach?.toString() === "true" ? true : false,
    have_system_monitoring: formValues?.have_system_monitoring?.toString() === "true" ? true : false,
    have_feedback_mechanism: formValues?.have_feedback_mechanism?.toString() === "true" ? true : false,
    have_system_track: formValues?.have_system_track?.toString() === "true" ? true : false,
    experienced_staff: formValues?.experienced_staff?.toString() === "true" ? true : false,
    regular_audited: formValues?.regular_audited?.toString() === "true" ? true : false,
    major_accountability_issues_highlighted: formValues?.major_accountability_issues_highlighted?.toString() === "true" ? true : false,
    regular_capacity_assessments: formValues?.regular_capacity_assessments?.toString() === "true" ? true : false,
    publish_annual_reports: formValues?.publish_annual_reports?.toString() === "true" ? true : false,
    audit_reports:
      formValues.regular_audited?.toString() === "true"
        ? additionalParams?.audits?.map((item) => ({
            org_audit: formValues[getKeyName("audit_reports_org_audit", item.id)],
            most_recent_audit_report: formValues[getKeyName("audit_reports_most_recent_audit_report", item.id)]?.id,
            audit_link_report: formValues[getKeyName("audit_reports_audit_link_report", item.id)],
            id: item.originalId ?? undefined,
          }))
        : [],
    capacity_assessments:
      formValues.regular_capacity_assessments?.toString() === "true"
        ? additionalParams?.assessments?.map((item) => ({
            assessment_type: formValues[getKeyName("capacity_assessments_assessment_type", item.id)],
            report_file: formValues[getKeyName("capacity_assessments_report_file", item.id)]?.id,
            id: item.originalId ?? undefined,
          }))
        : [],
    internal_controls: adaptAreasOfResponsibilityToBeSent(areasOfResponsibility, formValues, partner),
    area_policies: adaptAreasOfPolicyToBeSent(areasOfPolicy, formValues, partner),
  };
};

export const adaptOtherInformationFormValuesToBeSent = (formValues, partner, additionalParams) => {
  let collaboration_evidences = [];
  if (formValues.any_accreditation?.toString() === "true") {
    collaboration_evidences = additionalParams?.accreditation?.map((item) => ({
      mode: "Acc",
      organization_name: formValues[getKeyName("accreditation_organization_name", item.id)],
      date_received: formatDateToBeSent(formValues[getKeyName("accreditation_date_received", item.id)]),
      evidence_file_id: formValues[getKeyName("accreditation_evidence_file", item.id)]?.id,
      editable: item?.editable ?? true,
      id: item.originalId ?? undefined,
    }));
  }
  return {
    org_logo: partner?.other_info?.org_logo?.id !== formValues?.org_logo?.id ? formValues?.org_logo?.id : undefined,
    other_doc_1: partner?.other_info?.other_doc_1?.id !== formValues?.other_doc_1?.id ? formValues?.other_doc_1?.id : undefined,
    other_doc_2: partner?.other_info?.other_doc_2?.id !== formValues?.other_doc_2?.id ? formValues?.other_doc_2?.id : undefined,
    other_doc_3: partner?.other_info?.other_doc_3?.id !== formValues?.other_doc_3?.id ? formValues?.other_doc_3?.id : undefined,
    other_doc_4: partner?.other_info?.other_doc_4?.id !== formValues?.other_doc_4?.id ? formValues?.other_doc_4?.id : undefined,
    other_doc_5: partner?.other_info?.other_doc_5?.id !== formValues?.other_doc_5?.id ? formValues?.other_doc_5?.id : undefined,
    other_doc_6: partner?.other_info?.other_doc_6?.id !== formValues?.other_doc_6?.id ? formValues?.other_doc_6?.id : undefined,
    other_doc_7: partner?.other_info?.other_doc_7?.id !== formValues?.other_doc_7?.id ? formValues?.other_doc_7?.id : undefined,
    other_doc_8: partner?.other_info?.other_doc_8?.id !== formValues?.other_doc_8?.id ? formValues?.other_doc_8?.id : undefined,
    other_doc_9: partner?.other_info?.other_doc_9?.id !== formValues?.other_doc_9?.id ? formValues?.other_doc_9?.id : undefined,
    info_to_share: partner?.other_info?.info_to_share !== formValues?.info_to_share ? formValues?.info_to_share : undefined,
    any_accreditation: formValues.any_accreditation?.toString() === "true" ? true : false,
    confirm_data_updated: partner?.other_info?.confirm_data_updated?.toString() !== formValues?.confirm_data_updated?.toString() ? formValues?.confirm_data_updated?.toString() === "true" : undefined,
    collaboration_evidences,
  };
};

export const adaptUNAgencies = (agencies) => agencies.filter((agency) => allowedAgencies.includes(agency.value)).map((item) => item);
