import Chip from "@mui/material/Chip";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CircleIcon from "@mui/icons-material/Circle";
import i18n from "@local/i18next/i18n";
import { adaptReportsCountries } from "./adapters";
import Tooltip from "@mui/material/Tooltip";
import { getReviewerTextStatus, getStatusColor } from ".";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import IconButton from "@mui/material/IconButton";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { userStatusColor } from "@local/styles/muiTheme";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Rule } from "@mui/icons-material";
import { CALCULATION_STATUSES, DUPLICATE_STATUSES } from "@local/utils/constants";

export const getProjectAdminReviewersColumns = (completeReviewerAssessments, sendReminderToReviewer) => [
  { id: "id", title: i18n.t("Reviewer Id"), isVisible: false },
  { id: "fullname", title: i18n.t("Full Name") },
  { id: "email", title: i18n.t("Email") },
  { id: "application_status", title: i18n.t("Status"), type: "custom", render: (row) => <span>{i18n.t(getReviewerTextStatus(row?.application_status))}</span> },
  {
    id: "actions",
    title: i18n.t("Actions"),
    type: "custom",
    render: (row) => (
      <div>
        <div style={{ display: "inline-block", marginRight: "10px" }}>
          <IconButton disabled={row.application_status === "marked_reviews_completed"} size="small" onClick={() => sendReminderToReviewer(row?.id)}>
            <Tooltip title={i18n.t("Send notification to reviewer to complete their review")}>
              <NotificationsActiveIcon />
            </Tooltip>
          </IconButton>
        </div>
        {/*CLIENT HASN'T DECIDED IF THEY WANT THIS FUNCTIONALITY commented out for now*/}
        {/* <div style={{ display: "inline-block" }}>
          <IconButton disabled={row.application_status === "marked_reviews_completed" || row.application_status === "reviews_in_progress" || row.application_status === "reviews_not_started"} size="small" onClick={() => completeReviewerAssessments(row?.id)}>
            <AssignmentTurnedInIcon />
          </IconButton>
        </div> */}
      </div>
    ),
  },
];

export const getPartnerDuplicatesColumns = (onClickPartnerDuplicateRow) => [
  { id: "partner.id", title: i18n.t("Partner ID"), isVisible: false },
  { id: "partner.legal_name", title: i18n.t("Partner 1 Legal Name"), isVisible: true },
  { id: "duplicate_partner.id", title: i18n.t("Duplicate Partner ID"), isVisible: false },
  { id: "duplicate_partner.legal_name", title: i18n.t("Partner 2 Legal Name"), isVisible: true },
  { id: "duplicate_result", title: i18n.t("Calculation Result"), isVisible: true, type: "custom", render: (row) => <span>{row?.duplicate_result ? i18n.t(CALCULATION_STATUSES[row.duplicate_result]) : "--"}</span> },
  { id: "status", title: i18n.t("Status"), isVisible: true, type: "custom", render: (row) => <span>{i18n.t(DUPLICATE_STATUSES[row.status])}</span> },
  {
    id: "duplicate_object_id",
    title: i18n.t("Duplicate Partner"),
    isVisible: true,
    type: "custom",
    render: (row) => <span>{row.duplicate_object_id ? (row.duplicate_object_id === row.partner.id ? row.partner.legal_name : row.duplicate_partner.legal_name) : "--"}</span>,
  },
  {
    id: "action",
    title: i18n.t("Actions"),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ marginRight: ".5rem" }}>
          <IconButton size="small" onClick={() => onClickPartnerDuplicateRow(row)}>
            {row.is_duplicate === null ? <Rule /> : <InfoIcon />}
          </IconButton>
        </div>
      );
    },
  },
];

export const getProjectAdminColumns = (countries, sectors, onClickProjectRow) => [
  {
    id: "id",
    title: i18n.t("Project ID"),
    isVisible: false,
  },
  {
    id: "title",
    title: i18n.t("Project"),
  },
  {
    id: "country_location",
    title: i18n.t("Country Location"),
    type: "custom",
    render: (row) => (
      <Tooltip title={row.location_points.map((item) => item?.admin_level_1?.name ?? "--").join(", ")} arrow>
        <span>{adaptReportsCountries(row.country_code, countries)}</span>
      </Tooltip>
    ),
  },
  {
    id: "specializations",
    title: i18n.t("Sector & Area of Specialization"),
    type: "custom",
    render: (row) => {
      const rowSpecializationsIds = row.specializations.map((specialization) => specialization.id);
      const getSectorSpecializationsIds = (sector) => sector.specializations.map((specialization) => specialization.id);
      const rowSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => rowSpecializationsIds.includes(sectorSpecializationId)));
      return (
        <Tooltip title={row.specializations.map((item) => item.name).join(", ")} arrow>
          <span>{rowSectors.map((item) => item.name).join(", ")}</span>
        </Tooltip>
      );
    },
  },
  {
    id: "code_cfei_focal",
    title: i18n.t("CFEI ID & CFEI Focal Point"),
    type: "custom",
    render: (row) => {
      return (
        <Tooltip title={row.focal_points.map((item) => item.name).join(", ")} arrow>
          <span>{row.displayID}</span>
        </Tooltip>
      );
    },
  },
  {
    id: "created",
    title: i18n.t("Date Posted"),
    type: "date",
  },
  {
    id: "deadline_date",
    title: i18n.t("Application Deadline"),
    type: "date",
  },
  {
    id: "status",
    title: i18n.t("Status"),
    type: "custom",
    render: (row) => {
      return <Chip icon={<CircleIcon style={{ fill: getStatusColor(row.status) }} fontSize="small" />} label={formatMessage({ id: `utils.constants.${row.status}` })} />;
    },
  },
  {
    id: "action",
    title: i18n.t("Actions"),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ marginRight: ".5rem" }}>
          <IconButton size="small" onClick={() => onClickProjectRow(row)}>
            <EditIcon />
          </IconButton>
        </div>
      );
    },
  },
];

export const getPartnersColumns = (partnerTypes, specialPartnerTypes, countries, onClickPartnerRow) => [
  {
    id: "id",
    title: formatMessage({ id: "pages.reports.list.utils.columns.id" }),
    isVisible: true,
  },
  {
    id: "legal_name",
    title: formatMessage({ id: "pages.reports.list.utils.columns.legalName" }),
  },
  {
    id: "display_type",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.organizationType",
    }),
    type: "custom",
    render: (row) => <span>{partnerTypes?.find((item) => row.display_type === item.value)?.label || "--"}</span>,
  },
  {
    id: "country",
    title: formatMessage({ id: "pages.reports.list.utils.columns.country" }),
    type: "custom",
    render: (row) => <span>{countries?.find((item) => row.country_code === item.value)?.label || "--"}</span>,
  },
  {
    id: "action",
    title: i18n.t("Actions"),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ marginRight: ".5rem" }}>
          <IconButton size="small" onClick={() => onClickPartnerRow(row)}>
            <EditIcon />
          </IconButton>
        </div>
      );
    },
  },
];

export const getPartnerUsersColumns = (statuses, onClickUserRow, onClickEditUser) => [
  { id: "user.id", title: "Id", isVisible: false },
  { id: "user.fullname", title: i18n.t("Name") },
  { id: "role", title: i18n.t("Role") },
  { id: "user.email", title: i18n.t("Email") },
  {
    id: "user.status",
    title: i18n.t("Status"),
    type: "custom",
    render: (row) => {
      const status = statuses?.find((item) => item.value === row.user.status);
      if (status) {
        return <Chip icon={<CircleIcon style={{ fill: userStatusColor[status.value] }} fontSize="small" />} label={status.label} />;
      }
      return "--";
    },
  },
  {
    id: "action",
    title: i18n.t("Actions"),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: ".5rem" }}>
            <IconButton size="small" onClick={() => onClickEditUser(row)}>
              <EditIcon />
            </IconButton>
          </div>
          <div style={{ marginRight: ".5rem" }}>
            <IconButton size="small" onClick={async () => await onClickUserRow(row, !row.user.is_active)}>
              {row.user.is_active ? (
                <Tooltip title={i18n.t("Deactivate User's Account")}>
                  <DoDisturbIcon />
                </Tooltip>
              ) : (
                <Tooltip title={i18n.t("Activate User's Account")}>
                  <CheckCircleOutlineIcon />
                </Tooltip>
              )}
            </IconButton>
          </div>
        </div>
      );
    },
  },
];

export const similarityProperties = () => [
  { label: i18n.t("Identification"), key: "", isSection: true },
  { label: i18n.t("Partner ID"), key: "id" },
  { label: i18n.t("Organization's Legal Name"), key: "legal_name" },
  { label: i18n.t("Organization Type"), key: "additional_details.organization_type" },
  { label: i18n.t("Document Name"), key: "registration_documents[0].document.name" },
  { label: i18n.t("Registration Number"), key: "registration_documents[0].registration_number" },
  { label: i18n.t("Issuing Authority"), key: "registration_documents[0].issuing_authority" },
  { label: i18n.t("Issue Date"), key: "registration_documents[0].issue_date" },
  { label: i18n.t("Contact Information"), key: "", isSection: true },
  { label: i18n.t("Street"), key: "mailing_address.street" },
  { label: i18n.t("City"), key: "mailing_address.city" },
  { label: i18n.t("Country"), key: "mailing_address.country" },
  { label: i18n.t("Zip Code"), key: "mailing_address.zip_code" },
  { label: i18n.t("Telephone"), key: "mailing_address.mailing_telephone" },
  { label: i18n.t("Email"), key: "mailing_address.org_email" },
  { label: i18n.t("Website"), key: "mailing_address.website" },
  { label: i18n.t("Additional Information"), key: "", isSection: true },
  { label: i18n.t("Profile Completed"), key: "additional_details.profile_completed" },
  { label: i18n.t("Partner Verified"), key: "additional_details.partner_verified" },
  { label: i18n.t("Partner Code Provided by"), key: "additional_details.vendors" },
  { label: i18n.t("Number of CFEI’s"), key: "additional_details.number_of_cfei" },
  { label: i18n.t("Administrator Email"), key: "additional_details.oldest_admin_email" },
  { label: i18n.t("Last Update"), key: "additional_details.latest_profile_update" },
  { label: i18n.t("Last Login"), key: "additional_details.latest_partner_member_login" },
];
