/* eslint-disable no-undef */

import React, { useState, useEffect, useRef } from "react";
import style from "./style.module.scss";
import banner from "@local/assets/images/bannerV2.png";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";

const Banner = ({ children, role }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const timeout = useRef(undefined);

  useEffect(() => {
    const closedBannerId = localStorage.getItem("closedBannerId");
    if (closedBannerId !== "psea-webinar-notification") {
      const id = setTimeout(() => {
        setIsBannerVisible(true);
      }, 2000);
      timeout.current = id;
    }
    return () => clearTimeout(timeout.current);
  }, []);

  const onClickBanner = () => {
    window.open("https://supportcso.unpartnerportal.org/hc/en-us/sections/14883240182807-PSEA-Module-User-Guides-and-Resource-Materials", "_blank", "noopener");
    setIsBannerVisible(false);
    localStorage.setItem("closedBannerId", "psea-webinar-notification");
  };

  const onClickClose = () => {
    setIsBannerVisible(false);
    localStorage.setItem("closedBannerId", "psea-webinar-notification");
  };

  return (
    <>
      <div style={{ backgroundImage: `url(${banner})` }} className={isBannerVisible ? `${style.bannerWrapper} ${style.bannerWrapperVisible}` : `${style.bannerWrapper} ${style.bannerWrapperHidden}`}>
        <div className={style.bannerTopWrapper}>
          <div>
            <span className={style.text}>For more information on the Protection from Sexual Exploitation and Abuse (PSEA) module, and to learn how to use it, we encourage you to review the user guides and recordings from previous webinars.</span>
          </div>
          <div>
            <div className={style.closeButtonWrapper}>
              <CloseIcon
                onClick={onClickClose}
                style={{
                  cursor: "pointer",
                  color: "#fefefe",
                  fontSize: "1.25rem",
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.bannerBottomWrapper}>
          {isBannerVisible ? (
            <span onClick={onClickBanner} className={style.button}>
              View Resource
            </span>
          ) : undefined}
        </div>
      </div>
      <>{children}</>
    </>
  );
};

Banner.propTypes = {};

const mapStateToProps = (state) => ({
  role: state?.session?.role ?? {},
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
