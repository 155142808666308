/* eslint-disable import/no-named-as-default */

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import persistState from "redux-localstorage";
import * as R from "ramda";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import cfei from "../legacy-reducers/cfei";
import cfeiNav from "../legacy-reducers/cfeiNav";
import newCfei from "../legacy-reducers/newCfei";
import nav from "../legacy-reducers/nav";
import session from "../legacy-reducers/session";
import offices from "../legacy-reducers/offices";
import countries from "../legacy-reducers/countries";
import deleteClarificationAnswer from "../legacy-reducers/deleteClarificationAnswer";
import addClarificationRequest from "../legacy-reducers/addClarificationRequest";
import addClarificationAnswer from "../legacy-reducers/addClarificationAnswer";
import clarificationRequests, * as clarificationRequestsSelector from "../legacy-reducers/clarificationRequests";
import clarificationAnswers, * as clarificationAnswersSelector from "../legacy-reducers/clarificationAnswers";
import countryProfiles from "../legacy-reducers/countryProfiles";
import partnerProfileEdit from "../legacy-reducers/partnerProfileEdit";
import partnerProfileDetails from "../legacy-reducers/partnerProfileDetails";
import cfeiDetails, * as cfeiDetailsSelector from "../legacy-reducers/cfeiDetails";
import cfeiDetailsNav, { selectItemsByType } from "../legacy-reducers/cfeiDetailsNav";
import agencyPartnersList from "../legacy-reducers/agencyPartnersList";
import agencyPartnerProfileNav from "../legacy-reducers/agencyPartnerProfileNav";
import agencyPartnerProfile from "../legacy-reducers/agencyPartnerProfile";
import agencyMembersList from "../legacy-reducers/agencyMembersList";
import applicationsNotesList from "../legacy-reducers/applicationsNotesList";
import applicationsUnsolicitedList from "../legacy-reducers/applicationsUnsolicitedList";
import applicationsDirectList from "../legacy-reducers/applicationsDirectList";
import conceptNote from "../legacy-reducers/conceptNote";
import commonFileUpload from "../legacy-reducers/commonFileUpload";
import organizationProfileNav from "../legacy-reducers/organizationProfileNav";
import partnerApplicationsNav from "../legacy-reducers/partnerApplicationsNav";
import partnerProfileConfig from "../legacy-reducers/partnerProfileConfig";
import partnerProfileDetailsUpdate from "../legacy-reducers/partnerProfileDetailsUpdate";
import partnerProfileDetailsNav from "../legacy-reducers/partnerProfileDetailsNav";
import sectors, * as sectorsSelectors from "../legacy-reducers/sectors";
import partnersApplicationsList from "../legacy-reducers/partnersApplicationsList";
import partnersPreselectionList from "../legacy-reducers/partnersPreselectionList";
import selectionCriteria from "../legacy-reducers/selectionCriteria";
import adminOneLocation from "../legacy-reducers/adminOneLocation";
import partnerNames from "../legacy-reducers/partnerNames";
import applicationDetails, * as applicationDetailsSelector from "../legacy-reducers/applicationDetails";
import applicationReviews, * as applicationReviewsSelector from "../legacy-reducers/applicationReviews";
import agencyMembers, * as agencyMembersSelectors from "../legacy-reducers/agencyMembers";
import partnerAppDetails, * as partnerAppDetailsSelector from "../legacy-reducers/partnerApplicationDetails";
import agencies from "../legacy-reducers/agencies";
import applicationFeedback, * as applicationFeedbackSelector from "../legacy-reducers/applicationFeedback";
import recommendedPartners, * as recommendedPartnersSelector from "../legacy-reducers/recommendedPartners";
import preselectedPartners, * as preselectedPartnersSelector from "../legacy-reducers/preselectedPartners";
import partnerVerifications, * as partnerVerificationsSelector from "../legacy-reducers/partnerVerifications";
import cfeiReviewSummary, { selectReviewSummary } from "../legacy-reducers/cfeiReviewSummary";
import cfeiAwardedPartners, { selectAwardedPartners } from "../legacy-reducers/cfeiAwardedPartners";
import cfeiReviewers, { selectReviewers } from "../legacy-reducers/cfeiReviewers";
import dashboard from "../legacy-reducers/dashboard";
import partnerFlags, * as partnerFlagsSelector from "../legacy-reducers/partnerFlags";
import agencyPartnerApplicationList from "../legacy-reducers/agencyPartnerApplicationList";
import applicationsToScore from "../legacy-reducers/applicationsToScore";
import pendingOffers from "../legacy-reducers/pendingOffers";
import submittedCN from "../legacy-reducers/submittedCN";
import cache from "../legacy-reducers/cache";
import error, * as errorSelector from "../legacy-reducers/errorReducer";
import routesHistory from "../legacy-reducers/routesHistory";
import openCfeiDashboardList from "../legacy-reducers/openCfeiDashboardList";
import publishCfei from "../legacy-reducers/publishCfei";
import sendCfei from "../legacy-reducers/sendCfei";
import deleteCfei from "../legacy-reducers/deleteCfei";
import deleteUcn from "../legacy-reducers/deleteUcn";
import submitUcn from "../legacy-reducers/submitUcn";
import partnerObservationsList from "../legacy-reducers/agencyPartnerObservationsList";
import reportsPartnerList from "../legacy-reducers/reportsPartnerInformationList";
import reportsCfeiManagementList from "../legacy-reducers/reportsCfeiManagementList";
import reportVerificationList from "../legacy-reducers/reportsVerificationList";
import selectableList from "../legacy-reducers/selectableListItems";
import generatePartnerReports from "../legacy-reducers/partnerReportsGeneration";
import vendorNumber from "../legacy-reducers/vendorNumber";
import removeVendorNumber from "../legacy-reducers/deleteVendorNumber";
import partnerUnData from "../legacy-reducers/partnerUnData";
import completeAssessment from "../legacy-reducers/completeAssessment";
import sendCfeiForDecision from "../legacy-reducers/sendCfeiForDecision";
import locale from "../legacy-reducers/localeReducer";
import list from "../reducers/list";
import update from "../reducers/update";
import create from "../reducers/create";
import details from "../reducers/details";
import destroy from "../reducers/destroy";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    clarificationAnswers,
    clarificationRequests,
    sendCfeiForDecision,
    addClarificationAnswer,
    addClarificationRequest,
    deleteClarificationAnswer,
    completeAssessment,
    recommendedPartners,
    preselectedPartners,
    partnerUnData,
    vendorNumber,
    removeVendorNumber,
    cfei,
    cfeiNav,
    cfeiDetails,
    cfeiDetailsNav,
    newCfei,
    publishCfei,
    deleteCfei,
    sendCfei,
    deleteUcn,
    submitUcn,
    selectableList,
    reportsPartnerList,
    reportsCfeiManagementList,
    reportVerificationList,
    generatePartnerReports,
    organizationProfileNav,
    partnerApplicationsNav,
    applicationsNotesList,
    applicationsUnsolicitedList,
    applicationsDirectList,
    openCfeiDashboardList,
    adminOneLocation,
    partnerObservationsList,
    nav,
    offices,
    session,
    error,
    countries,
    conceptNote,
    countryProfiles,
    commonFileUpload,
    partnerProfileConfig,
    partnerProfileEdit,
    partnerProfileDetails,
    partnerProfileDetailsNav,
    partnerProfileDetailsUpdate,
    agencyMembersList,
    agencyPartnersList,
    agencyPartnerProfileNav,
    agencyPartnerProfile,
    form: formReducer,
    routing: connectRouter(history),
    sectors,
    partnersApplicationsList,
    partnersPreselectionList,
    selectionCriteria,
    partnerNames,
    applicationDetails,
    applicationReviews,
    agencyMembers,
    partnerAppDetails,
    applicationFeedback,
    agencies,
    agencyPartnerApplicationList,
    partnerVerifications,
    cfeiReviewSummary,
    cfeiAwardedPartners,
    cfeiReviewers,
    dashboard,
    partnerFlags,
    applicationsToScore,
    pendingOffers,
    submittedCN,
    cache,
    routesHistory,
    locale,
    unDataDetails: details("UN_DATA_DETAILS"),
    unDocumentsList: list("UN_DOCUMENTS_LIST"),
    createPartnerUnDocument: list("UN_DOCUMENT_CREATE"),
    getPartnerUnDocumentItem: details("UN_DOCUMENT_DETAILS"),
    updatePartnerUnDocument: update("UN_DOCUMENT_UPDATE"),
    destroyPartnerUnDocument: destroy("UN_DOCUMENT_DELETE"),
    getPartnerVendorNumberLinkItem: details("VENDOR_NUMBER_LINK_DETAILS"),
    updatePartnerVendorNumberLink: create("VENDOR_NUMBER_LINK_UPDATE"),
    destroyPartnerVendorNumberLink: destroy("VENDOR_NUMBER_LINK_DELETE"),
    specialPartnerDetails: details("SPECIAL_PARTNER_DETAILS"),
    specialPartnerOnEdit: details("SPECIAL_PARTNER_ON_EDIT"),
    specialPartners: details("SPECIAL_PARTNERS"),
    subNationals: details("SPECIAL_PARTNER_SUB_NATIONALS"),
    loadPartnerNames: details("HQ_PARTNERS_NAMES"),
    applicationsToScoreList: list("APPLICATIONS_TO_SCORE_LIST"),
    openCfeisList: list("OPEN_CFEIS_LIST"),
    partnerDecisionsList: list("PARTNER_DECISIONS_LIST"),
    partnersName: list("PARTNER_NAMES"),
    openProjectsList: list("OPEN_PROJECTS_LIST"),
    pinnedProjectsList: list("PINNED_PROJECTS_LIST"),
    directProjectsList: list("DIRECT_PROJECTS_LIST"),
    unsolicitedProjectsList: list("UNSOLICITED_PROJECTS_LIST"),
    countriesList: list("COUNTRIES_LIST"),
    locationsList: list("LOCATIONS_LIST"),
    focalPointsList: list("FOCAL_POINTS_LIST"),
    agenciesList: list("AGENCIES_LIST"),
    sectorsList: list("SECTORS_LIST"),
    agencyOfficesList: list("AGENCY_OFFICES_LIST"),
    agencyAdminsList: list("AGENCY_ADMINS_LIST"),
    firstAccessPartner: create("PARTNER_FIRST_ACCESS_CREATE"),
    firstAccessAgency: create("AGENCY_FIRST_ACCESS_CREATE"),
    partnersList: list("PARTNERS_LIST"),
    partnerSummaryDetails: details("PARTNER_SUMMARY_DETAILS"),
    partnerProfileDataDetails: details("PARTNER_PROFILE_DATA_DETAILS"),
    partnerProfileVerifications: list("PARTNER_PROFILE_VERIFICATIONS_LIST"),
    partnerVendorNumberRequestCreate: create("PARTNER_VENDOR_REQUEST_CREATE"),
    deletePartnerVendorNumberRequest: destroy("PARTNER_VENDOR_REQUEST_DESTROY"),
    partnerVendorNumberRequestDetails: details("PARTNER_VENDOR_REQUEST_DETAILS"),
    riskProfileDetails: details("RISK_PROFILE_DETAILS"),
    assessmentReportsList: list("ASSESSMENT_REPORTS"),
    auditReportsList: list("AUDIT_REPORTS"),
    progressReportsList: list("PROGRESS_REPORTS"),
    observationsList: list("RISK_OBSERVATIONS"),
    createObservation: create("RISK_OBSERVATION_CREATE"),
    updateObservation: update("RISK_OBSERVATION_UPDATE"),
    sanctionMatchDetails: details("SANCTION_MATCH_DETAILS"),
    flagsList: list("RISK_FLAGS"),
    flagReasonsList: list("FLAG_REASONS"),
    deletePartnerVendorNumber: destroy("PARTNER_VENDOR_REQUEST_DELETE"),
    createPartnerVendorNumber: create("PARTNER_VENDOR_NUMBER_CREATE"),
    partnerVerificationsFullList: list("PARTNER_VERIFICATIONS_FULL_LIST"),
    partnerFlagsList: list("PARTNER_FLAGS_LIST"),
    partnerApplicationsList: list("PARTNER_APPLICATIONS_LIST"),
    updateUserProfile: update("USER_PROFILE_UPDATE"),
    partnerMembersList: list("PARTNER_MEMBERS_LIST"),
    partnerReports: list("PARTNER_REPORTS_LIST"),
    partnerProfileReports: list("EXPORT_PARTNER_PROFILE_REPORTS"),
    partnerContactReports: list("EXPORT_PARTNER_CONTACT_REPORTS"),
    partnerMappingReports: list("EXPORT_PARTNER_MAPPING_REPORTS"),
    projectReports: list("PROJECT_REPORTS_LIST"),
    projectDetailsReports: list("EXPORT_PROJECTS_DETAILS_REPORTS"),
    verificationsReports: list("VERIFICATION_REPORTS_LIST"),
    verificationsDetailsReports: list("EXPORT_VERIFICATION_REPORTS"),
    partnersToInviteList: list("PARTNERS_TO_INVITE_LIST"),
    invitePartners: update("INVITE_PARTNERS_UPDATE"),
    createOpenProject: create("OPEN_PROJECT_CREATE"),
    projectDetails: details("PROJECT_DETAILS"),
    updateProjectDetails: update("PROJECT_DETAILS_UPDATE"),
    unProjectApplicationDetails: details("PROJECT_APPLICATION_DETAILS"),
    partnerProjectApplication: details("PARTNER_PROJECT_APPLICATION_DETAILS"),
    createDirectProject: create("OPEN_DIRECT_CREATE"),
    getOpenApplications: list("OPEN_APPLICATIONS_LIST"),
    getDirectApplications: list("DIRECT_APPLICATIONS_LIST"),
    getUnsolicitedApplications: list("UNSOLICITED_APPLICATIONS_LIST"),
    createUnsolicitedApplication: create("UNSOLICITED_APPLICATION_CREATE"),
    updateUnsolicitedApplication: update("UNSOLICITED_APPLICATION_UPDATE"),
    partnerProfiles: list("PARTNER_PROFILES_LIST"),
    updatePartnerIdentification: update("PARTNER_IDENTIFICATION_UPDATE"),
    updatePartnerMailing: update("PARTNER_MAILING_UPDATE"),
    updatePartnerMandateMission: update("PARTNER_MANDATE_MISSION_UPDATE"),
    updatePartnerFund: update("PARTNER_FUND_UPDATE"),
    updatePartnerCollaboration: update("PARTNER_COLLABORATION_UPDATE"),
    updatePartnerProjectImplementation: update("PARTNER_PROJECT_IMPLEMENTATION_UPDATE"),
    updatePartnerOtherInformation: update("PARTNER_OTHER_INFORMATION_UPDATE"),
    notificationsList: list("NOTIFICATIONS_LIST"),
    updateNotifications: update("NOTIFICATIONS_UPDATE"),
    openProjectRequests: list("OPEN_PROJECT_REQUESTS_LIST"),
    notificationsNotReadList: list("NOTIFICATIONS_NOT_READ_LIST"),
    createPartnerVerification: create("PARTNER_VERIFICATION_CREATE"),
    partnerVerificationsList: list("PARTNER_VERIFICATIONS_LIST"),
    clarificationRequestsList: list("CLARIFICATION_REQUESTS_LIST"),
    clarificationAnswersList: list("CLARIFICATION_ANSWERS_LIST"),
    reviewersList: list("REVIEWERS_LIST"),
    reviewSummary: details("REVIEW_SUMMARY_DETAILS"),
    updateProjectApplication: update("PROJECT_APPLICATION_UPDATE"),
    createPartnerFlag: create("PARTNER_FLAG_CREATE"),
    applicationReviewsList: list("APPLICATION_REVIEWS_LIST"),
    completeAssessments: create("COMPLETE_ASSESSMENT_CREATE"),
    sendProjectRequest: create("SEND_PROJECT_REQUEST_CREATE"),
    publishProjectRequest: create("PUBLISH_PROJECT_REQUEST_CREATE"),
    createShellProfile: create("SHELL_PROFILE_CREATE"),
    getShellProfilesList: list("SHELL_PROFILE_LIST"),
    createShellProfileNotification: create("SHELL_PROFILE_NOTIFICATION_CREATE"),
    partnerHqsList: list("PARTNER_HQS_LIST"),
    partnersBasicInformationList: list("PARTNER_BASIC_INFORMATION_LIST"),
    createPartnerRegistration: create("PARTNER_REGISTRATION_CREATE"),
    createSpecialPartner: create("SPECIAL_PARTNERS_CREATE"),
    updateSpecialPartner: update("SPECIAL_PARTNERS_UPDATE"),
    specialPartnerSubNationals: list("SPECIAL_PARTNER_SUB_NATIONALS_LIST"),
    conceptNoteFile: create("CONCEPT_NOTE_CREATE"),
    subscriptions: list("SUBSCRIPTIONS_LIST"),
    createSubscription: create("SUBSCRIPTION_CREATE"),
    updateSubscription: update("SUBSCRIPTION_UPDATE"),
    deleteSubscription: destroy("SUBSCRIPTION_DESTROY"),
    tags: list("TAGS_LIST"),
    getUsersList: list("USERS_LIST"),
    createUser: create("USER_CREATE"),
    updateUser: update("USER_UPDATE"),
    toggleUser: update("USER_TOGGLE"),
    officesList: list("OFFICES_LIST"),
    getRegionalOfficesList: list("REGIONAL_OFFICES_LIST"),
    createRegionalOffice: create("REGIONAL_OFFICE_CREATE"),
    updateRegionalOffice: update("REGIONAL_OFFICE_UPDATE"),
    deleteRegionalOffice: destroy("REGIONAL_OFFICE_DESTROY"),
    getRegionalOfficeMember: list("REGIONAL_OFFICE_MEMBER"),
    createRegionalOfficeMember: create("REGIONAL_OFFICE_MEMBER_CREATE"),
    createCountryProfile: create("COUNTRY_PROFILE_CREATE"),
    applicationFeedbackList: details("APPLICATION_FEEDBACK_LIST"),
    postApplicationFeedback: create("APPLICATION_FEEDBACK_CREATE"),
    partnerUnDataList: list("PARTNER_UN_DATA_LIST"),
    updateOpenProject: update("OPEN_PROJECT_UPDATE"),
    updateDirectProject: create("OPEN_DIRECT_UPDATE"),
    deleteProject: destroy("PROJECT_DELETE"),
    cancelProject: destroy("PROJECT_CANCEL_DELETE"),
    updateReviewers: update("PROJECT_REVIEWERS_UPDATE"),
    agencyReviewersList: list("AGENCY_REVIEWERS_LIST"),
    applicationComparison: list("APPLICATION_COMPARISON_LIST"),
    togglePin: update("TOGGLE_PIN_UPDATE"),
    agencyFocalPoints: list("AGENCY_FOCAL_POINTS"),
    agencyMemberReports: list("AGENCY_MEMBER_REPORTS_LIST"),
    rssChannelList: list("RSS_CHANNEL_LIST"),
    postUCN: create("UNSOLICITED_UCN_CREATE"),
    deleteUCN: destroy("UNSOLICITED_UCN_DELETE"),
    convertCnToDirectSelection: create("CONVERT_TO_DIRECT_SELECTION_CREATE"),
    openProjectApplicationsAllList: list("OPEN_PROJECT_APPLICATIONS_ALL_LIST"),
    openProjectApplicationsPreselectedList: list("OPEN_PROJECT_APPLICATIONS_PRESELECTED_LIST"),
    openProjectApplicationsRecommendedList: list("OPEN_PROJECT_APPLICATIONS_RECOMMENDED_LIST"),
    openProjectApplicationsPreselectedRecommendedList: list("OPEN_PROJECT_APPLICATIONS_PRESELECTED_RECOMMENDED_LIST"),
    submittedConceptNotesList: list("SUBMITTED_CONCEPT_NOTES_LIST"),
    pendingOffersList: list("PENDING_OFFERS_LIST"),
    pseaInitialAssessmentDetails: details("PSEA_INITIAL_ASSESSMENT_DETAILS"),
    updatePseaInitialAssessment: update("PSEA_INITIAL_ASSESSMENT_UPDATE"),
    pseaInitialAssessmentDocumentsList: list("PSEA_INITIAL_ASSESSMENT_DOCUMENTS_LIST"),
    createPseaInitialAssessmentDocument: create("PSEA_INITIAL_ASSESSMENT_DOCUMENT_CREATE"),
    deletePseaInitialAssessmentDocument: destroy("PSEA_INITIAL_ASSESSMENT_DOCUMENT_DELETE"),
    unDataList: list("UN_DATA_LIST"),
    pseaSelfAssessmentDetails: details("PSEA_SELF_ASSESSMENT_DETAILS"),
    updatePseaSelfAssessmentQuestion: update("PSEA_SELF_ASSESSMENT_QUESTION_UPDATE"),
    updatePseaSelfAssessment: update("PSEA_SELF_ASSESSMENT_UPDATE"),
    pseaSelfAssessmentDocumentsList: list("PSEA_SELF_ASSESSMENT_DOCUMENTS_LIST"),
    createPseaSelfAssessmentDocument: create("PSEA_SELF_ASSESSMENT_DOCUMENT_CREATE"),
    deletePseaSelfAssessmentDocument: destroy("PSEA_SELF_ASSESSMENT_DOCUMENT_DELETE"),
    startPseaCsip: details("PSEA_CSIP_START"),
    pseaCsipDetails: details("PSEA_CSIP_DETAILS"),
    pseaCsipCoreStandardDocuments: list("PSEA_CSIP_CORE_STANDARD_DOCUMENTS_LIST"),
    createPseaCsipCoreStandardDocument: create("PSEA_CSIP_CORE_STANDARD_DOCUMENT_CREATE"),
    deletePseaCsipCoreStandardDocument: destroy("PSEA_CSIP_CORE_STANDARD_DOCUMENT_DELETE"),
    createPseaCsipCoreStandardActivity: create("PSEA_CSIP_CORE_STANDARD_ACTIVITY_CREATE"),
    extendPseaCsipDeadline: update("PSEA_CSIP_DEADLINE_UPDATE"),
    completePseaCsip: update("PSEA_CSIP_COMPLETE_UPDATE"),
    pseaDetails: details("PSEA_DETAILS"),
    updatePseaDetails: update("PSEA_UPDATE"),
    pseaCsipActivitiesList: list("PSEA_CSIP_ACTIVITIES_LIST"),
    pseaSelfAssessmentJustificationsList: list("PSEA_SELF_ASSESSMENT_JUSTIFICATIONS_LIST"),
    ipAgreementData: details("IP_AGREEMENT_DATA"),
    updatePseaCsipCoreStandardComment: update("PSEA_CSIP_CORE_STANDARD_COMMENT"),
    updatePseaRevisionJustification: update("PSEA_UPDATE_REVISION_JUSTIFICATION"),
    createAgencyMember: create("NEW_AGENCY_MEMBER_CREATE"),
    createPartnerDeclaration: create("PARTNER_DECLARATION_CREATE"),
    pseaRatingCommentsList: list("PSEA_RATING_COMMENTS_LIST"),
    createPseaRatingComment: create("PSEA_RATING_COMMENT_CREATE"),
    getAdminProjectsList: list("ADMIN_PROJECTS_LIST"),
    partnersDuplicate: details("ADMIN_PARTNER_DUPLICATES_PROFILE_DATA_DETAILS"),
  });

export const history = createBrowserHistory();

let composeEnhancers = compose;
if (process.env.NODE_ENV !== "production") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
}

const slicer = (paths) => (state) =>
  paths.reduce((acc, curr) => {
    const path = R.split(".", curr);

    return R.assocPath(path, R.path(path, state), acc);
  }, {});

const configureStore = (preloadedState) =>
  createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(thunk, routerMiddleware(history)),
      persistState(["session.partnerId", "session.newlyRegistered"], {
        slicer,
      }),
    ),
  );

export default configureStore();

const makeFormItem = (list) => {
  let [value, label] = list;
  if (!isNaN(value)) value = Number(value);
  return { value, label };
};

export const mapValuesForSelectionField = (state, compareField = "label") => {
  const compare = (a, b) => a[compareField].localeCompare(b[compareField]);
  return R.sort(compare, R.map(makeFormItem, R.toPairs(state)));
};

export const mapArrayForSelectionField = (state, compareField = "label") => {
  const compare = (a, b) => a[compareField].localeCompare(b[compareField]);
  return R.sort(
    compare,
    R.map((item) => makeFormItem(R.values(item)), state),
  );
};

export const selectNormalizedCountries = (state) => mapValuesForSelectionField(state.countries);

export const selectNormalizedOrganizationTypes = (state) => mapValuesForSelectionField(state.partnerProfileConfig["partner-type"]);

export const selectNormalizedOrganizationTypesWithoutSpecialType = (state) => mapValuesForSelectionField(state.partnerProfileConfig["partner-type-without-special-type"]);

export const selectNormalizedOrganizationTypesSpecialType = (state) => mapValuesForSelectionField(state.partnerProfileConfig["special-partner-type"]);

export const selectNormalizedYearsOfExperience = (state) => mapValuesForSelectionField(state.partnerProfileConfig["years-of-exp-choices"], "value");

export const selectNormalizedWorkingLanguages = (state) => mapValuesForSelectionField(state.partnerProfileConfig["working-languages"]);

export const selectNormalizedPopulationsOfConcernGroups = (state) => mapValuesForSelectionField(state.partnerProfileConfig["population-of-concern"]);

export const selectNormalizedStaffGlobalyChoices = (state) => mapValuesForSelectionField(state.partnerProfileConfig["staff-globaly-choices"], "value");

export const selectNormalizedBudgets = (state) => mapValuesForSelectionField(state.partnerProfileConfig["budget-choices"], "value");

export const selectNormalizedBusinessAreas = (state) => mapValuesForSelectionField(state.partnerProfileConfig["business-areas"], "value");

export const selectNormalizedDirectJustification = (state) => mapValuesForSelectionField(state.partnerProfileConfig["direct-justifications"], "value");

export const selectApplicationStatuses = (state) => state.partnerProfileConfig["application-statuses"];

export const selectExtendedApplicationStatuses = (state) => state.partnerProfileConfig["extended-application-statuses"];

export const selectNormalizedExtendedApplicationStatuses = (state) => mapValuesForSelectionField(state.partnerProfileConfig["extended-application-statuses"]);

export const selectOrganizationTypes = (state) => state.partnerProfileConfig["partner-type"];

export const selectNormalizedApplicationStatuses = (state) => mapValuesForSelectionField(state.partnerProfileConfig["application-statuses"]);

export const selectNormalizedAuditTypes = (state) => mapValuesForSelectionField(state.partnerProfileConfig["audit-types"]);

export const selectNormalizedNotificationsFrequencies = (state) => {
  let frequncies = state.partnerProfileConfig["notification-frequency-choices"];
  frequncies = R.dissoc("", frequncies);
  frequncies["0_disabled"] = "Disabled (no e-mail notifications)";

  return mapValuesForSelectionField(frequncies, "value");
};

export const selectNormalizedCapacityAssessments = (state) => mapValuesForSelectionField(state.partnerProfileConfig["formal-capacity-assessment"]);

export const selectNormalizedPartnerDonors = (state) => mapValuesForSelectionField(state.partnerProfileConfig["partner-donors"]);

export const selectNormalizedMethodAccAdopted = (state) => mapValuesForSelectionField(state.partnerProfileConfig["method-acc-adopted-choices"]);

export const selectNormalizedFinancialControlSystem = (state) => mapValuesForSelectionField(state.partnerProfileConfig["financial-control-system-choices"]);

export const selectNormalizedFunctionalResponsibility = (state) => mapValuesForSelectionField(state.partnerProfileConfig["functional-responsibility-choices"]);

export const selectNormalizedPolicyArea = (state) => mapValuesForSelectionField(state.partnerProfileConfig["policy-area-choices"]);

export const selectNormalizedDirectSelectionSource = (state) => mapValuesForSelectionField(state.partnerProfileConfig["direct-selection-source"]);

export const selectNormalizedCfeiTypes = (state) => mapValuesForSelectionField(state.partnerProfileConfig["cfei-types"]);

export const selectNormalizedCfeiStatuses = (state) => mapValuesForSelectionField(state.partnerProfileConfig["cfei-statuses"]);

export const selectNormalizedFlagTypes = (state) => mapValuesForSelectionField(state.partnerProfileConfig["flag-types"], "value");

export const selectCountriesWithOptionalLocations = (state) => state.partnerProfileConfig["countries-with-optional-location"];

export const selectNormalizedRoleChoices = (state) => mapValuesForSelectionField(state.partnerProfileConfig["user-role-choices"]);

export const selectNormalizedAdminPartnerRoleChoices = (state) => mapValuesForSelectionField(state.partnerProfileConfig["admin-partner-role-choices"]);

export const selectNormalizedRoleFilter = (state) => mapValuesForSelectionField(state.partnerProfileConfig["user-role-filter"]);

export const selectNormalizedStatusFilter = (state) => mapValuesForSelectionField(state.partnerProfileConfig["user-status-filter"]);

export const selectNormalizedOffices = (state) => mapArrayForSelectionField(state.offices["user-offices-choices"]);

export const selectNormalizedOfficesFilter = (state) => mapArrayForSelectionField(state.offices["user-offices-filter"]);

export const selectNormalizedSpecializations = (state) => mapValuesForSelectionField(state.sectors.allSpecializations);

export const selectNormalizedPopulations = (state) => mapValuesForSelectionField(state.population);

export const mapSectorsToSelection = (state) => mapValuesForSelectionField(sectorsSelectors.selectAllSectors(state.sectors));

export const mapSpecializationsToSelection = (state, sectorId) => mapValuesForSelectionField(sectorsSelectors.selectSpecializationsForSector(state.sectors, sectorId));

export const selectSector = (state, id) => sectorsSelectors.selectSector(state.sectors, id);

export const selectSpecializations = (state, ids) => sectorsSelectors.selectSpecializations(state.sectors, ids);

export const selectMappedSpecializations = (state) => sectorsSelectors.selectMappedSpecializations(state.sectors);

export const selectCfeiDetailsItemsByType = (state, type) => selectItemsByType(state.cfeiDetailsNav, type);

export const selectCfeiDetails = (state, id) => cfeiDetailsSelector.selectCfeiDetail(state.cfeiDetails.data, id);

export const selectCfeiTitle = (state, id) => cfeiDetailsSelector.selectCfeiTitle(state.cfeiDetails.data, id);

export const selectCfeiAgency = (state, id) => cfeiDetailsSelector.selectCfeiAgency(state.cfeiDetails.data, id);

export const selectCfeiCriteria = (state, id) => cfeiDetailsSelector.selectCfeiCriteria(state.cfeiDetails.data, id);

export const selectCfeiStatus = (state, id) => cfeiDetailsSelector.selectCfeiStatus(state.cfeiDetails.data, id);

export const selectCfeiDisplayStatus = (state, id) => cfeiDetailsSelector.selectCfeiDisplayStatus(state.cfeiDetails.data, id);

export const selectCfeiConverted = (state, id) => cfeiDetailsSelector.selectCfeiConverted(state.cfeiDetails.data, id);

export const selectCfeiCompletedReason = (state, id) => cfeiDetailsSelector.selectCfeiCompletedReason(state.cfeiDetails.data, id);

export const selectCfeiCompletedReasonDisplay = (state, id) => cfeiDetailsSelector.selectCfeiCompletedReasonDisplay(state.cfeiDetails.data, id);

export const isCfeiCompleted = (state, id) => cfeiDetailsSelector.isCfeiCompleted(state.cfeiDetails.data, id);

export const selectPartnerVerified = (state, id) => cfeiDetailsSelector.selectPartnerVerified(state.cfeiDetails.data, id);

export const selectPartnerFlagStatus = (state, id) => cfeiDetailsSelector.selectPartnerFlagStatus(state.cfeiDetails.data, id);

export const isUserFinishedReview = (state, id) => cfeiDetailsSelector.isUserFinishedReview(state.cfeiDetails.data, id);

export const isUserCompletedAssessment = (state, id) => cfeiDetailsSelector.isUserCompletedAssessment(state.cfeiDetails.data, id);

export const isCfeiPublished = (state, id) => cfeiDetailsSelector.isCfeiPublished(state.cfeiDetails.data, id);

export const isCfeiDeadlinePassed = (state, id) => cfeiDetailsSelector.isCfeiDeadlinePassed(state.cfeiDetails.data, id);

export const isCfeiClarificationDeadlinePassed = (state, id) => cfeiDetailsSelector.isCfeiClarificationDeadlinePassed(state.cfeiDetails.data, id);

export const cfeiHasRecommendedPartner = (state, id) => cfeiDetailsSelector.cfeiHasRecommendedPartner(state.cfeiDetails.data, id);

export const cfeiHasPartnerAccepted = (state, id) => cfeiDetailsSelector.cfeiHasPartnerAccepted(state.cfeiDetails.data, id);

export const isCfeiPinned = (state, id) => cfeiDetailsSelector.isCfeiPinned(state.cfeiDetails.data, id);

export const selectCfeiWinnersStatus = (state, id) => cfeiDetailsSelector.selectCfeiWinnersStatus(state.cfeiDetails.data, id);

export const mapSelectCriteriaToSelection = (state) => mapValuesForSelectionField(state.selectionCriteria);

export const mapPartnersNamesToSelection = (state) => mapValuesForSelectionField(state.partnerNames);

export const selectApplicationStatus = (state, id) => applicationDetailsSelector.selectApplicationStatus(state.applicationDetails.data, id);

export const selectApplicationPartnerName = (state, id) => applicationDetailsSelector.selectApplicationPartnerName(state.applicationDetails.data, id);

export const selectApplicationProject = (state, id) => applicationDetailsSelector.selectApplicationProject(state.applicationDetails.data, id);

export const selectApplication = (state, id) => applicationDetailsSelector.selectApplication(state.applicationDetails.data, id);

export const selectApplicationWithdrawalStatus = (state, id) => applicationDetailsSelector.selectApplicationWithdrawStatus(state.applicationDetails.data, id);

export const selectApplicationCurrentStatus = (state, id) => applicationDetailsSelector.selectApplicationCurrentStatus(state.applicationDetails.data, id);

export const selectReview = (state, reviewId) => applicationReviewsSelector.selectReview(state.applicationReviews, reviewId);

export const selectReviewer = (state, reviewerId) => applicationReviewsSelector.selectReviewer(state.applicationReviews, reviewerId);

export const selectAssessment = (state, assessmentId) => applicationReviewsSelector.selectAssessment(state.applicationReviews, assessmentId);

export const isAssesmentAdded = (state, assessmentId) => applicationReviewsSelector.isAssesmentAdded(state.applicationReviews, assessmentId);

export const mapFocalPointsReviewersToSelection = (state) => mapValuesForSelectionField(agencyMembersSelectors.selectPossibleFocalPointsReviewers(state.agencyMembers.data));

export const cfeiDetailsReviewers = (state, cfeiId) => cfeiDetailsSelector.cfeiDetailsReviewers(state.cfeiDetails.data, cfeiId, state.session.userId);

export const isUserACreator = (state, cfeiId) => cfeiDetailsSelector.isUserACreator(state.cfeiDetails.data, cfeiId, state.session.userId);

export const isUserAReviewer = (state, cfeiId) => cfeiDetailsSelector.isUserAReviewer(state.cfeiDetails.data, cfeiId, state.session.userId);

export const isUserAFocalPoint = (state, cfeiId) => cfeiDetailsSelector.isUserAFocalPoint(state.cfeiDetails.data, cfeiId, state.session.userId);

export const isSendForDecision = (state, cfeiId) => cfeiDetailsSelector.isSendForDecision(state.cfeiDetails.data, cfeiId, state.session.userId);

export const selectNormalizedCompletionReasons = (state) => mapValuesForSelectionField(state.partnerProfileConfig["completed-reason"]);

export const selectNormalizedFlagCategoryChoices = (state) => mapValuesForSelectionField(state.partnerProfileConfig["flag-category-choices"], "value");

export const selectAllFlagCategoryChoices = (state) => mapValuesForSelectionField(state.partnerProfileConfig["all-flag-category-choices"], "value");

export const selectNormalizedFlagTypeChoices = (state) => mapValuesForSelectionField(state.partnerProfileConfig["flag-type-choices"], "value");

export const selectNormalizedDsrFinalizeOptions = (state) => mapValuesForSelectionField(state.partnerProfileConfig["direct-selection-completed-reason"]);

export const selectNormalizedTimePeriods = (state) => mapValuesForSelectionField(state.partnerProfileConfig["direct-selection-retention"], "value");

export const selectPartnerApplicationDetails = (state, cfeiId) => partnerAppDetailsSelector.selectApplication(state.partnerAppDetails, cfeiId);

export const selectApplicationFeedback = (state, applicationId) => applicationFeedbackSelector.selectFeedback(state.applicationFeedback, applicationId);

export const selectApplicationFeedbackCount = (state, applicationId) => applicationFeedbackSelector.selectCount(state.applicationFeedback, applicationId);

export const selectRecommendedPartnersCount = (state, cfeiId) => recommendedPartnersSelector.selectCount(state.recommendedPartners, cfeiId);

export const selectRecommendedPartners = (state, cfeiId) => recommendedPartnersSelector.selectPartners(state.recommendedPartners, cfeiId);

export const selectClarificationRequestsCount = (state, cfeiId) => clarificationRequestsSelector.selectCount(state.clarificationRequests, cfeiId);

export const selectClarificationRequests = (state, cfeiId) => clarificationRequestsSelector.selectRequests(state.clarificationRequests, cfeiId);

export const selectClarificationAnswersCount = (state, cfeiId) => clarificationAnswersSelector.selectCount(state.clarificationAnswers, cfeiId);

export const selectClarificationAnswers = (state, cfeiId) => clarificationAnswersSelector.selectAnswers(state.clarificationAnswers, cfeiId);

export const selectPreselectedPartnersCount = (state, cfeiId) => preselectedPartnersSelector.selectCount(state.preselectedPartners, cfeiId);

export const selectPreselectedPartners = (state, cfeiId) => preselectedPartnersSelector.selectPartners(state.preselectedPartners, cfeiId);

export const selectPartnerVerifications = (state, partnerId) => partnerVerificationsSelector.selectVerifications(state.partnerVerifications, partnerId);

export const selectMostRecentVerification = (state, partnerId) => partnerVerificationsSelector.selectMostRecentVerification(state.partnerVerifications, partnerId);

export const selectPreviousVerificationCount = (state, partnerId) => partnerVerificationsSelector.selectPreviousVerificationsCount(state.partnerVerifications, partnerId);

export const mapAgenciesNamesToSelection = (state) => mapValuesForSelectionField(state.agencies.data);

export const selectCfeiReviewSummary = (state, cfeiId) => selectReviewSummary(state.cfeiReviewSummary.data, cfeiId);

export const selectCfeiReviewers = (state, cfeiId) => selectReviewers(state.cfeiReviewers.data, cfeiId);

export const selectCfeiAwardedPartners = (state, cfeiId) => selectAwardedPartners(state.cfeiAwardedPartners.data, cfeiId);

export const selectPartnerFlags = (state, partnerId) => partnerFlagsSelector.selectPartnerFlags(state.partnerFlags, partnerId);

export const selectPartnerFlagsCount = (state, partnerId) => partnerFlagsSelector.selectPartnerFlagsCount(state.partnerFlags, partnerId);

export const selectAllErrorsMapped = (state) => errorSelector.selectAllErrorsMapped(state.error);

export const isUserHq = (state) => state.session.isHq;

export const selectUserHqId = (state) => state.session.hqId;

export const selectUserPartnerId = (state) => state.session.partnerId;
