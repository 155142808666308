import React from "react";
import { connect } from "react-redux";
import Card from "@local/components/card";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";

const backendMsg = {
  "Sector expertise and experience": "backend.common.consts.SELECTION_CRITERIA_.SEE",
  "Project management": "backend.common.consts.SELECTION_CRITERIA_.Pro",
  "Local experience and presence": "backend.common.consts.SELECTION_CRITERIA_.LEP",
  "Contribution of resource": "backend.common.consts.SELECTION_CRITERIA_.Con",
  "Cost effectiveness": "backend.common.consts.SELECTION_CRITERIA_.Cos",
  "Experience working with UN": "backend.common.consts.SELECTION_CRITERIA_.Exp",
  "Relevance of proposal to achieving expected results": "backend.common.consts.SELECTION_CRITERIA_.Rel",
  "Clarity of activities and expected results": "backend.common.consts.SELECTION_CRITERIA_.Cla",
  "Innovative approach": "backend.common.consts.SELECTION_CRITERIA_.Inn",
  "Sustainability of intervention": "backend.common.consts.SELECTION_CRITERIA_.Sus",
  "Realistic timelines and plans": "backend.common.consts.SELECTION_CRITERIA_.Rea",
  "Access/security considerations": "backend.common.consts.SELECTION_CRITERIA_.ASC",
  Other: "backend.common.consts.SELECTION_CRITERIA_.Oth",
};

const SelectionCriteria = ({ project, allCriteria }) => {
  return (
    <Card
      title={formatMessage({
        id: "pages.project.details.cmp.overview.cmp.selection.criteria.idx.title",
      })}
    >
      <div>
        {project?.assessments_criteria.map((selection) => (
          <div className={style.itemWrapper} key={selection?.selection_criteria}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: backendMsg[allCriteria[selection.selection_criteria]],
              })}
            </span>
            <span className={style.itemValue}>{selection?.description}</span>
            {selection?.weight ? (
              <div>
                <span className={style.itemValue}>
                  {formatMessage({
                    id: "pages.project.details.cmp.overview.cmp.selection.criteria.idx.weight",
                  })}
                  : {selection.weight}{" "}
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </Card>
  );
};

SelectionCriteria.propTypes = {};

const mapStateToProps = (state) => ({
  allCriteria: state?.selectionCriteria,
});

export default connect(mapStateToProps)(SelectionCriteria);
