import React, { useState } from "react";
import Table from "@local/components/table";
import i18n from "@local/i18next/i18n";
import Modal from "@local/components/modal";
import { connect } from "react-redux";
import { Tab, Tabs } from "@mui/material";

import ProjectForm from "./components/ProjectForm";
import { getProjectAdminReviewersColumns } from "../../utils/columns";
import { completeProjectReviewerAssessments, sendReminder } from "@local/actions";
import { toast } from "react-toastify";
import { projectModalNav } from "@local/utils/constants";
import Loader from "@local/components/loader";
import style from "./style.module.scss";
import ConfirmModal from "@local/components/confirm-modal";
const AssessmentFinalizations = ({ tabs, initialFilters, fetchFunction, columns, filters, adapterFunction, isOpen, onClose, onChangeTableFilters, selectedProject, completeReviewerAssessments, shouldRefresh, setShouldRefresh, sendReminderToReviewer }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [reviewerId, setReviewerId] = useState(null);
  // Handle change of tab
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNotifyIconClick = (reviwerId) => {
    setIsNotifyModalOpen(true);
    setReviewerId(reviwerId);
  };

  const handleConfirmationClick = () => {
    sendReminderToReviewer(reviewerId);
    setIsNotifyModalOpen(false);
    setReviewerId(null);
  };

  let content;

  switch (activeTab) {
    case 0:
      content = <ProjectForm setShouldRefresh={setShouldRefresh} initialValues={selectedProject} />;
      break;
    case 1:
      content = (
        <div className={style.tableWrapper}>
          <Table
            key={`project_reviewers_list${selectedProject?.id}`}
            id="project_reviewers_list"
            title={i18n.t("Reviewers")}
            isPaginated={false}
            columns={getProjectAdminReviewersColumns(completeReviewerAssessments, handleNotifyIconClick)}
            fetchFunction={() => ({
              data: {
                count: selectedProject?.reviewers ? selectedProject?.reviewers?.length : 0,
                results: selectedProject?.reviewers ? selectedProject?.reviewers : [],
              },
            })}
            adapterFunction={adapterFunction}
            onChangeFilters={onChangeTableFilters}
            keyColumn="id"
          />
        </div>
      );
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Table
        key="projects_list"
        id="projects_list"
        title={i18n.t("Projects")}
        columns={columns}
        fetchFunction={fetchFunction}
        filters={filters}
        shouldRefresh={shouldRefresh}
        afterRefresh={() => setShouldRefresh(false)}
        adapterFunction={adapterFunction}
        onChangeFilters={onChangeTableFilters}
        withUrlSynchronization
        keyColumn="id"
        initialFilters={initialFilters}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setActiveTab(0);
          onClose();
        }}
        title={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{i18n.t("Project Details")}</span>
            <span className={style.projectIdLabel}>CFEI ID: {selectedProject?.displayID}</span>
          </div>
        }
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className={style.projectIdLabel}>Title</span>
          <span className={style.itemValue}> {selectedProject?.title}</span>
        </div>
        <Tabs value={activeTab} onChange={handleChangeTab} variant="scrollable" scrollButtons={false} allowScrollButtonsMobile>
          {tabs.map((tab, index) => (
            <Tab key={tab.id} value={index} label={tab.label} disabled={tab.isDisabled} />
          ))}
        </Tabs>
        <div className={style.modal}>
          {selectedProject ? (
            content
          ) : (
            <div className={style.loaderWrapper}>
              <Loader isVisible />
            </div>
          )}
        </div>
      </Modal>
      <ConfirmModal isOpen={isNotifyModalOpen} title={i18n.t("Do you confirm sending notification to the reviewer?")} onSubmit={handleConfirmationClick} onClose={() => setIsNotifyModalOpen(false)} />
    </>
  );
};

AssessmentFinalizations.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    tabs: projectModalNav(),
    selectedProject: state?.getAdminProjectsList?.data?.data?.results?.find((result) => result?.id === ownProps?.selectedProject?.id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  completeReviewerAssessments: async (id) => {
    try {
      await dispatch(completeProjectReviewerAssessments(null, { id: ownProps?.selectedProject?.id, reviewer_id: id }));
      toast.success(i18n.t("Assessments completed successfully"));
      ownProps?.setShouldRefresh(true);
    } catch (error) {
      toast.error(i18n.t("Action failed"));
    }
  },
  sendReminderToReviewer: async (id) => {
    try {
      const res = await dispatch(sendReminder(null, { id: ownProps?.selectedProject?.id, reviewerId: id }));
      if (res.data.success) {
        toast.success(i18n.t(res.data.success));
      }
    } catch (error) {
      toast.error(i18n.t("Notification message failed to sent"));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentFinalizations);
